import React from 'react';

import { useRouter } from 'next/router';
import { TransparentButton } from '../../common';
import StoresList from '../stores-list/StoresList';

const FeaturedStores = () => {
  const router = useRouter();

  return (
    <section className="mb-12 xl:mb-0">
      <div className="flex items-center justify-between mb-7.5 md:mb-9">
        <h2 className="redDot text-xl font-bold md:text-2xl">
          Featured restaurants
        </h2>
        <TransparentButton
          iconRight="chevron-right-circle"
          iconRightAlt="Right chevron"
          onClick={() => {
            router.push('/restaurants');
          }}
        >
          See All
        </TransparentButton>
      </div>

      <StoresList layoutMode="grid" limit={6} featured />
    </section>
  );
};

export default FeaturedStores;
