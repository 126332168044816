import React, { useRef } from 'react';
import { NextPage } from 'next';

import { useIntersection } from 'react-use';
import FeaturedStores from '../components/stores/featured-stores/FeaturedStores';
import HomeSearch from '../components/layout/home-search/HomeSearch';
import Header from '../components/layout/header/Header';
import Footer from '../components/layout/footer/Footer';
import { NextPageAuthProps } from '../types/auth';
import { withAuth } from '../helpers/auth';

const Home: NextPage<NextPageAuthProps> = () => {
  const mainSearchContainerElement = useRef<HTMLDivElement>(null);

  const mainSearchIntersection = useIntersection(mainSearchContainerElement, {
    rootMargin: '0px',
    threshold: 1,
  });

  return (
    <>
      <Header mainSearchIntersection={mainSearchIntersection} />

      <main>
        <h1 className="sr-only">Homepage</h1>

        <HomeSearch ref={mainSearchContainerElement} />

        <div className="container">
          <FeaturedStores />
        </div>
      </main>

      <Footer />
    </>
  );
};

export const getServerSideProps = withAuth();

export default Home;
