import React, { AnchorHTMLAttributes, forwardRef } from 'react';

import style from './CategoryPreview.module.scss';

export type IconType =
  | 'category-drinks'
  | 'category-sashimi'
  | 'category-sweets'
  | 'category-udon'
  | 'category-vegan'
  | 'category-vegetarian';

type Props = {
  iconName: IconType;
  name: string;
} & Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'onClick'>;

const CategoryPreview = forwardRef<HTMLAnchorElement, Props>(
  ({ iconName, name, href, onClick }, ref) => (
    <a
      className={style.hoverBottomSemiCircle}
      ref={ref}
      href={href}
      onClick={onClick}
    >
      <div className={style.wrap}>
        <div className="h-24 flex items-center justify-center relative z-20 h-30.5">
          <img src={`/icons/${iconName}.svg`} alt={name} aria-hidden="true" />
        </div>
        <div className={style.name} data-testid="name">
          {name}
        </div>
      </div>
    </a>
  )
);

export default CategoryPreview;
