import Image from 'next/image';
import React, { forwardRef } from 'react';

import CategoryList from '../../categories/category-list/CategoryList';
import MainSearch from '../main-search/MainSearch';

import styles from './HomeSearch.module.scss';

const HomeSearch = forwardRef<HTMLDivElement>((_, searchContainerElement) => (
  <section className={styles.wrapper}>
    <Image
      src="/images/background-search.png"
      alt="Asian food background image"
      className="object-center object-cover pointer-events-none"
      loading="eager"
      quality={50}
      layout="fill"
    />

    <div
      ref={searchContainerElement}
      className="container relative text-center"
    >
      <MainSearch kind="homepage" />
    </div>

    <div className={styles.categories}>
      <CategoryList />
    </div>
  </section>
));

export default HomeSearch;
