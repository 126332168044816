import React from 'react';
import Link from 'next/link';

import CategoryPreview, { IconType } from '../category-preview/CategoryPreview';

import styles from './CategoryList.module.scss';

const CATEGORY_LIST: Array<{ icon: IconType; title: string }> = [
  {
    icon: 'category-vegan',
    title: 'Vegan',
  },
  {
    icon: 'category-vegetarian',
    title: 'Vegetarian',
  },
  {
    icon: 'category-udon',
    title: 'Udon',
  },
  {
    icon: 'category-sashimi',
    title: 'Sashimi',
  },
  {
    icon: 'category-drinks',
    title: 'Drinks',
  },
  {
    icon: 'category-sweets',
    title: 'Sweets',
  },
];

const CategoryList: React.FC = () => (
  <div className={styles.wrapper}>
    <div className={styles.content}>
      {CATEGORY_LIST.map((category, index) => (
        <Link
          key={`category-item${index}`}
          href={`/restaurants?search=${category.title.toLowerCase()}`}
          passHref
        >
          <CategoryPreview iconName={category.icon} name={category.title} />
        </Link>
      ))}
    </div>
  </div>
);

export default CategoryList;
